import React from 'react';
import Button from 'devextreme-react/button';
import { TabContent } from 'reactstrap';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import './report.css';
import httpPost from '../../utils/http-post';
import httpPut from '../../utils/http-put';
import DamageInfoTabComponent from './tabs/damage-info-tab';
import InsurancePolicyInfoTabComponent from './tabs/insurance-policy-info-tab';
import InjuredCarDriverInfoTabComponent from './tabs/injured-car-driver-info-tab';
import InjuredCarInfoTabComponent from './tabs/injured-car-info-tab';
import InsuredCarDriverInfoTabComponent from './tabs/insured-car-driver-info-tab';
import InsuredCarInfoTabComponent from './tabs/insured-car-info-tab';
import NotesTabComponent from './tabs/notes-tab';
import ServiceInfoTabComponent from './tabs/service-info-tab';
import WorkmanshipTabComponent from './tabs/workmanship-tab';
import {
    accidentDateChangedHandler, accidentPlaceChangedHandler, accidentCityTownChangedHandler,
    accidentTimeChangedHandler, accidentTypeChangedHandler, caseReportTypeChangedHandler, injuredCarNameChangedHandler, injuredCarIdentityTypeChangedHandler,
    injuredCarBrandNameChangedHandler, injuredCarModelYearChangedHandler,
    injuredCarChassisNumberChangedHandler, injuredCarPlateNoChangedHandler,
    injuredCarUsageTypeChangedHandler, injuredCarIdentityValueChangedHandler,
    injuredCarModelNameChangedHandler, injuredCarKilometerChangedHandler,
    injuredCarLicenseSerialNoChangedHandler, injuredCarTrafficRegisterDateChangedHandler,
    injuredCarMotorNoChangedHandler, injuredCarDriverRatioChangedHandler,
    injuredCarDriverIdentityChangedHandler, injuredCarDriverDocumentNoChangedHandler,
    injuredCarDriverNameChangedHandler, injuredCarDriverClassChangedHandler,
    injuredCarDriverSufficiencyChangedHandler, injuredCarDriverAlcoholStatusChangedHandler,
    expertNoteChangedHandler, exemptionNoteChangedHandler, accidentNoteChangedHandler,
    incidentNoteChangedHandler, insuranceNoteChangedHandler, otherNoteChangedHandler,
    notesChangedHandler, insuranceShapeChangedHandler, agencyCodeChangedHandler,
    policyStartChangedHandler, policyNoChangedHandler, insuranceCompanyChangedHandler,
    serviceNameChangedHandler, serviceNumberChangedHandler,
    serviceTaxNumberChangedHandler, insuredCarDriverRatioChangedHandler,
    insuredCarDriverNameChangedHandler, insuredCarDriverClassChangedHandler,
    insuredCarDriverAlcoholChangedHandler, insuredCarDriverIdentityChangedHandler,
    insuredCarDriverDocumentChangedHandler, insuredCarDriverPromilleChangedHandler,
    insuredCarBrandChangedHandler, insuredCarModelYearChangedHandler, insuredCarPlateChangedHandler,
    insuredCarChassisNoChangedHandler, insuredCarCarOwnerChangedHandler, insuredCarLicenseChangedHandler,
    insuredCarModelNameChangedHandler, insuredCarMotorNoChangedHandler, insuredCarOutToTrafficDateChangedHandler,
    insuredCarUsageTypeChangedHandler, serviceAddressChangedHandler,
    insuredCarIdentityTypeChangedHandler, insuredCarIdentityValueChangedHandler, insuredCarKilometerChangedHandler,
    injuredCarDriverPromilleChangedHandler,
    expertPriceChangedHandler, towTruckPriceChangedHandler
} from './helpers/handlers';

import FileSaver from 'file-saver';
import { locale } from 'devextreme/localization';
import getBlobFromFile from '../../utils/blob-file';

export default class extends React.Component {

    constructor(props) {

        console.log(process.env.REACT_APP_BASE_URL);

        super(props);


        this.accidentDateChangedHandler = accidentDateChangedHandler.bind(this);
        this.accidentPlaceChangedHandler = accidentPlaceChangedHandler.bind(this);
        this.accidentCityTownChangedHandler = accidentCityTownChangedHandler.bind(this);
        this.accidentTimeChangedHandler = accidentTimeChangedHandler.bind(this);
        this.accidentTypeChangedHandler = accidentTypeChangedHandler.bind(this);
        this.caseReportTypeChangedHandler = caseReportTypeChangedHandler.bind(this);

        this.injuredCarNameChangedHandler = injuredCarNameChangedHandler.bind(this);
        this.injuredCarIdentityTypeChangedHandler = injuredCarIdentityTypeChangedHandler.bind(this);
        this.injuredCarBrandNameChangedHandler = injuredCarBrandNameChangedHandler.bind(this);
        this.injuredCarModelYearChangedHandler = injuredCarModelYearChangedHandler.bind(this);
        this.injuredCarChassisNumberChangedHandler = injuredCarChassisNumberChangedHandler.bind(this);
        this.injuredCarPlateNoChangedHandler = injuredCarPlateNoChangedHandler.bind(this);
        this.injuredCarUsageTypeChangedHandler = injuredCarUsageTypeChangedHandler.bind(this);
        this.injuredCarIdentityValueChangedHandler = injuredCarIdentityValueChangedHandler.bind(this);
        this.injuredCarModelNameChangedHandler = injuredCarModelNameChangedHandler.bind(this);
        this.injuredCarKilometerChangedHandler = injuredCarKilometerChangedHandler.bind(this);
        this.injuredCarLicenseSerialNoChangedHandler = injuredCarLicenseSerialNoChangedHandler.bind(this);
        this.injuredCarTrafficRegisterDateChangedHandler = injuredCarTrafficRegisterDateChangedHandler.bind(this);
        this.injuredCarMotorNoChangedHandler = injuredCarMotorNoChangedHandler.bind(this);

        this.injuredCarDriverRatioChangedHandler = injuredCarDriverRatioChangedHandler.bind(this);
        this.injuredCarDriverIdentityChangedHandler = injuredCarDriverIdentityChangedHandler.bind(this);
        this.injuredCarDriverDocumentNoChangedHandler = injuredCarDriverDocumentNoChangedHandler.bind(this);
        this.injuredCarDriverNameChangedHandler = injuredCarDriverNameChangedHandler.bind(this);
        this.injuredCarDriverClassChangedHandler = injuredCarDriverClassChangedHandler.bind(this);
        this.injuredCarDriverSufficiencyChangedHandler = injuredCarDriverSufficiencyChangedHandler.bind(this);
        this.injuredCarDriverAlcoholStatusChangedHandler = injuredCarDriverAlcoholStatusChangedHandler.bind(this);
        this.injuredCarDriverPromilleChangedHandler = injuredCarDriverPromilleChangedHandler.bind(this);

        this.expertNoteChangedHandler = expertNoteChangedHandler.bind(this);
        this.exemptionNoteChangedHandler = exemptionNoteChangedHandler.bind(this);
        this.accidentNoteChangedHandler = accidentNoteChangedHandler.bind(this);
        this.incidentNoteChangedHandler = incidentNoteChangedHandler.bind(this);
        this.insuranceNoteChangedHandler = insuranceNoteChangedHandler.bind(this);
        this.otherNoteChangedHandler = otherNoteChangedHandler.bind(this);
        this.notesChangedHandler = notesChangedHandler.bind(this);

        this.insuranceShapeChangedHandler = insuranceShapeChangedHandler.bind(this);
        this.agencyCodeChangedHandler = agencyCodeChangedHandler.bind(this);
        this.policyStartChangedHandler = policyStartChangedHandler.bind(this);
        this.policyNoChangedHandler = policyNoChangedHandler.bind(this);
        this.insuranceCompanyChangedHandler = insuranceCompanyChangedHandler.bind(this);


        this.serviceNameChangedHandler = serviceNameChangedHandler.bind(this);
        this.serviceNumberChangedHandler = serviceNumberChangedHandler.bind(this);
        this.serviceTaxNumberChangedHandler = serviceTaxNumberChangedHandler.bind(this);
        this.serviceAddressChangedHandler = serviceAddressChangedHandler.bind(this);

        this.insuredCarDriverRatioChangedHandler = insuredCarDriverRatioChangedHandler.bind(this);
        this.insuredCarDriverNameChangedHandler = insuredCarDriverNameChangedHandler.bind(this);
        this.insuredCarDriverClassChangedHandler = insuredCarDriverClassChangedHandler.bind(this);
        this.insuredCarDriverAlcoholChangedHandler = insuredCarDriverAlcoholChangedHandler.bind(this);
        this.insuredCarDriverIdentityChangedHandler = insuredCarDriverIdentityChangedHandler.bind(this);
        this.insuredCarDriverDocumentChangedHandler = insuredCarDriverDocumentChangedHandler.bind(this);
        this.insuredCarDriverPromilleChangedHandler = insuredCarDriverPromilleChangedHandler.bind(this);

        this.insuredCarBrandChangedHandler = insuredCarBrandChangedHandler.bind(this);
        this.insuredCarModelYearChangedHandler = insuredCarModelYearChangedHandler.bind(this);
        this.insuredCarPlateChangedHandler = insuredCarPlateChangedHandler.bind(this);
        this.insuredCarChassisNoChangedHandler = insuredCarChassisNoChangedHandler.bind(this);
        this.insuredCarCarOwnerChangedHandler = insuredCarCarOwnerChangedHandler.bind(this);
        this.insuredCarLicenseChangedHandler = insuredCarLicenseChangedHandler.bind(this);
        this.insuredCarModelNameChangedHandler = insuredCarModelNameChangedHandler.bind(this);
        this.insuredCarMotorNoChangedHandler = insuredCarMotorNoChangedHandler.bind(this);
        this.insuredCarOutToTrafficDateChangedHandler = insuredCarOutToTrafficDateChangedHandler.bind(this);
        this.insuredCarUsageTypeChangedHandler = insuredCarUsageTypeChangedHandler.bind(this);
        this.insuredCarIdentityTypeChangedHandler = insuredCarIdentityTypeChangedHandler.bind(this);
        this.insuredCarIdentityValueChangedHandler = insuredCarIdentityValueChangedHandler.bind(this);
        this.insuredCarKilometerChangedHandler = insuredCarKilometerChangedHandler.bind(this);

        this.workmanshipItemsChangedHandler = this.workmanshipItemsChangedHandler.bind(this);
        this.expertPriceChangedHandler = expertPriceChangedHandler.bind(this);
        this.towTruckPriceChangedHandler = towTruckPriceChangedHandler.bind(this);

        this.state = {
            accidentDate: this.props.editState.accidentDate,
            accidentPlace: this.props.editState.accidentPlace,
            accidentCityTown: this.props.editState.accidentCityTown,
            accidentTime: this.props.editState.accidentTime,
            accidentType: this.props.editState.accidentType,
            caseReportType: this.props.editState.caseReportType,

            injuredCarName: this.props.editState.injuredCarName,
            injuredCarIdentityType: this.props.editState.injuredCarIdentityType,
            injuredCarBrandName: this.props.editState.injuredCarBrandName,
            injuredCarModelYear: this.props.editState.injuredCarModelYear,
            injuredCarChassisNumber: this.props.editState.injuredCarChassisNumber,
            injuredCarPlateNo: this.props.editState.injuredCarPlateNo,
            injuredCarUsageType: this.props.editState.injuredCarUsageType,
            injuredCarIdentityValue: this.props.editState.injuredCarIdentityValue,
            injuredCarModelName: this.props.editState.injuredCarModelName,
            injuredCarKilometer: this.props.editState.injuredCarKilometer,
            injuredCarLicenseSerialNo: this.props.editState.injuredCarLicenseSerialNo,
            injuredCarTrafficRegisterDate: this.props.editState.injuredCarTrafficRegisterDate,
            injuredCarMotorNo: this.props.editState.injuredCarMotorNo,

            injuredCarDriverRatio: this.props.editState.injuredCarDriverRatio,
            injuredCarDriverIdentity: this.props.editState.injuredCarDriverIdentity,
            injuredCarDriverDocumentNo: this.props.editState.injuredCarDriverDocumentNo,
            injuredCarDriverName: this.props.editState.injuredCarDriverName,
            injuredCarDriverClass: this.props.editState.injuredCarDriverClass,
            injuredCarDriverSufficiency: this.props.editState.injuredCarDriverSufficiency,
            injuredCarDriverAlcoholStatus: this.props.editState.injuredCarDriverAlcoholStatus,
            injuredCarDriverPromille: this.props.editState.injuredCarDriverPromille,

            expertNote: this.props.editState.expertNote,
            exemptionNote: this.props.editState.exemptionNote,
            accidentNote: this.props.editState.accidentNote,
            incidentNote: this.props.editState.incidentNote,
            insuranceNote: this.props.editState.insuranceNote,
            otherNote: this.props.editState.otherNote,
            notes: this.props.editState.notes,

            insuranceShape: this.props.editState.insuranceShape,
            agencyCode: this.props.editState.agencyCode,
            policyStart: this.props.editState.policyStart,
            policyNo: this.props.editState.policyNo,
            insuranceCompany: this.props.editState.insuranceCompany,
            policyEnd: this.props.editState.policyEnd,

            serviceName: this.props.editState.serviceName,
            serviceNumber: this.props.editState.serviceNumber,
            serviceTaxNumber: this.props.editState.serviceTaxNumber,
            serviceAddress: this.props.editState.serviceAddress,

            insuredCarDriverRatio: this.props.editState.insuredCarDriverRatio,
            insuredCarDriverName: this.props.editState.insuredCarDriverName,
            insuredCarDriverClass: this.props.editState.insuredCarDriverClass,
            insuredCarDriverAlcohol: this.props.editState.insuredCarDriverAlcohol,
            insuredCarDriverIdentity: this.props.editState.insuredCarDriverIdentity,
            insuredCarDriverDocument: this.props.editState.insuredCarDriverDocument,
            insuredCarDriverPromille: this.props.editState.insuredCarDriverPromille,

            insuredCarBrand: this.props.editState.insuredCarBrand,
            insuredCarModelYear: this.props.editState.insuredCarModelYear,
            insuredCarPlate: this.props.editState.insuredCarPlate,
            insuredCarChassisNo: this.props.editState.insuredCarChassisNo,
            insuredCarCarOwner: this.props.editState.insuredCarCarOwner,
            insuredCarLicense: this.props.editState.insuredCarLicense,
            insuredCarModelName: this.props.editState.insuredCarModelName,
            insuredCarMotorNo: this.props.editState.insuredCarMotorNo,
            insuredCarOutToTrafficDate: this.props.editState.insuredCarOutToTrafficDate,
            insuredCarUsageType: this.props.editState.insuredCarUsageType,
            insuredCarIdentityType: this.props.editState.insuredCarIdentityType,
            insuredCarIdentityValue: this.props.editState.insuredCarIdentityValue,
            insuredCarKilometer: this.props.editState.insuredCarKilometer,

            expertPrice: this.props.editState.expertPrice,
            towTruckPrice: this.props.editState.towTruckPrice,

            id: this.props.editState.id,
            number: this.props.editState.number,
        };

        this.workmanshipItems = this.props.editState.workmanshipItems ? this.props.editState.workmanshipItems : [];


        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.nextTab = this.nextTab.bind(this);
        this.prevTab = this.prevTab.bind(this);

        this.tabs = [
            { id: 1, reportTypes: [1, 3], name: 'insured-car-info-tab', title: 'SİGORTALI ARAÇ BİLGİLERİ' },
            { id: 2, reportTypes: [1, 3], name: 'insured-car-driver-info-tab', title: ' SİGORTALI ARAÇ SÜRÜCÜ BİLGİLERİ' },
            { id: 3, reportTypes: [1, 2, 3], name: 'injured-car-info-tab', title: ' MAĞDUR ARAÇ BİLGİSİ' },
            { id: 4, reportTypes: [1, 2, 3], name: 'injured-car-driver-info-tab', title: 'MAĞDUR ARAÇ SÜRÜCÜ BİLGİSİ' },
            { id: 5, reportTypes: [1, 2, 3], name: 'damage-info-tab', title: 'KAZA-HASAR BİLGİSİ' },
            { id: 6, reportTypes: [1, 2, 3], name: 'insurance-policy-info-tab', title: 'ARACIN POLİÇE BİLGİLERİ' },
            { id: 7, reportTypes: [1, 2], name: 'service-info-tab', title: ' SERVİS BİLGİLERİ' },
            { id: 8, reportTypes: [1, 2], name: 'workmanship-tab', title: 'İŞÇİLİK' },
            { id: 9, reportTypes: [1, 2, 3], name: 'notes-tab', title: 'NOTLAR' }
        ]

        this.tabs = this.tabs.filter(x => x.reportTypes.includes(this.props.reportType));

        this.state.selectedTab = this.tabs[0].name;

        this.state.loadIndicatorVisible = false;
        this.state.currentTab = this.tabs[0].id;

        locale('tr');

        this.state.isLastTab = false;
        this.state.isFirstTab = true;
    }

    nextTab() {
        this.handleOnSelect(this.tabs.filter(x => x.id > this.state.currentTab)[0].id);
    }

    prevTab() {
        const prevItems = this.tabs.filter(x => x.id < this.state.currentTab);
        this.handleOnSelect(prevItems[prevItems.length - 1].id);
    }

    handleOnSelect(selected) {
        this.setState({
            currentTab: selected,
            selectedTab: this.tabs.filter(x => x.id === selected)[0].name,
            isLastTab: selected === this.tabs[this.tabs.length - 1].id,
            isFirstTab: selected === this.tabs[0].id
        });
    }

    render() {
        return (

            <div>

                <div className="multisteps-form">
                    <div className="row">
                        <div className="col-12 ml-auto mr-auto mb-4">
                            <div className="multisteps-form__progress">
                                {this.tabs.map((item, key) =>
                                    <button key={key}
                                        className={'multisteps-form__progress-btn ' + (item.id <= this.state.currentTab ? 'js-active' : '')}
                                        type="button"
                                        title={item.title}
                                        onClick={() => { this.handleOnSelect(item.id); }}>{item.title}</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <TabContent activeTab={this.state.selectedTab}>

                    <DamageInfoTabComponent accidentDateChanged={this.accidentDateChangedHandler}
                        accidentPlaceChanged={this.accidentPlaceChangedHandler}
                        accidentCityTownChanged={this.accidentCityTownChangedHandler}
                        accidentTimeChanged={this.accidentTimeChangedHandler}
                        accidentTypeChanged={this.accidentTypeChangedHandler}
                        caseReportTypeChanged={this.caseReportTypeChangedHandler}
                        editState={this.state}
                    />

                    <InjuredCarInfoTabComponent nameChanged={this.injuredCarNameChangedHandler}
                        identityTypeChanged={this.injuredCarIdentityTypeChangedHandler}
                        brandNameChanged={this.injuredCarBrandNameChangedHandler}
                        modelYearChanged={this.injuredCarModelYearChangedHandler}
                        chassisNumberChanged={this.injuredCarChassisNumberChangedHandler}
                        plateNoChanged={this.injuredCarPlateNoChangedHandler}
                        usageTypeChanged={this.injuredCarUsageTypeChangedHandler}
                        identityValueChanged={this.injuredCarIdentityValueChangedHandler}
                        modelNameChanged={this.injuredCarModelNameChangedHandler}
                        kilometerChanged={this.injuredCarKilometerChangedHandler}
                        licenseSerialNoChanged={this.injuredCarLicenseSerialNoChangedHandler}
                        trafficRegisterDateChanged={this.injuredCarTrafficRegisterDateChangedHandler}
                        motorNoChanged={this.injuredCarMotorNoChangedHandler}
                        editState={this.state} />

                    <InjuredCarDriverInfoTabComponent ratioChanged={this.injuredCarDriverRatioChangedHandler}
                        identityChanged={this.injuredCarDriverIdentityChangedHandler}
                        documentNoChanged={this.injuredCarDriverDocumentNoChangedHandler}
                        nameChanged={this.injuredCarDriverNameChangedHandler}
                        classChanged={this.injuredCarDriverClassChangedHandler}
                        sufficiencyChanged={this.injuredCarDriverSufficiencyChangedHandler}
                        alcoholStatusChanged={this.injuredCarDriverAlcoholStatusChangedHandler}
                        promilleChanged={this.injuredCarDriverPromilleChangedHandler}
                        editState={this.state} />

                    <NotesTabComponent expertNoteChanged={this.expertNoteChangedHandler}
                        exemptionNoteChanged={this.exemptionNoteChangedHandler}
                        accidentNoteChanged={this.accidentNoteChangedHandler}
                        incidentNoteChanged={this.incidentNoteChangedHandler}
                        insuranceNoteChanged={this.insuranceNoteChangedHandler}
                        otherNoteChanged={this.otherNoteChangedHandler}
                        notesChanged={this.notesChangedHandler}
                        editState={this.state} />

                    <WorkmanshipTabComponent workmanshipCallback={this.workmanshipItemsChangedHandler}
                        expertPriceChanged={this.expertPriceChangedHandler}
                        towTruckPriceChanged={this.towTruckPriceChangedHandler}
                        editState={this.state}
                        workmanshipItems={this.props.editState.workmanshipItems} />

                    <InsurancePolicyInfoTabComponent insuranceShapeChanged={this.insuranceShapeChangedHandler}
                        agencyCodeChanged={this.agencyCodeChangedHandler}
                        policyStartChanged={this.policyStartChangedHandler}
                        policyNoChanged={this.policyNoChangedHandler}
                        insuranceCompanyChanged={this.insuranceCompanyChangedHandler}
                        upperState={this.state} />

                    <ServiceInfoTabComponent serviceNameChanged={this.serviceNameChangedHandler}
                        serviceNumberChanged={this.serviceNumberChangedHandler}
                        serviceTaxNumberChanged={this.serviceTaxNumberChangedHandler}
                        serviceAddressChanged={this.serviceAddressChangedHandler}
                        editState={this.state} />

                    <InsuredCarDriverInfoTabComponent ratioChanged={this.insuredCarDriverRatioChangedHandler}
                        nameChanged={this.insuredCarDriverNameChangedHandler}
                        classChanged={this.insuredCarDriverClassChangedHandler}
                        alcoholChanged={this.insuredCarDriverAlcoholChangedHandler}
                        identityChanged={this.insuredCarDriverIdentityChangedHandler}
                        documentChanged={this.insuredCarDriverDocumentChangedHandler}
                        promilleChanged={this.insuredCarDriverPromilleChangedHandler}
                        editState={this.state} />

                    <InsuredCarInfoTabComponent brandChanged={this.insuredCarBrandChangedHandler}
                        modelYearChanged={this.insuredCarModelYearChangedHandler}
                        plateChanged={this.insuredCarPlateChangedHandler}
                        chassisNoChanged={this.insuredCarChassisNoChangedHandler}
                        carOwnerChanged={this.insuredCarCarOwnerChangedHandler}
                        licenseChanged={this.insuredCarLicenseChangedHandler}
                        modelNameChanged={this.insuredCarModelNameChangedHandler}
                        motorNoChanged={this.insuredCarMotorNoChangedHandler}
                        outToTrafficDateChanged={this.insuredCarOutToTrafficDateChangedHandler}
                        usageTypeChanged={this.insuredCarUsageTypeChangedHandler}
                        identityTypeChanged={this.insuredCarIdentityTypeChangedHandler}
                        identityValueChanged={this.insuredCarIdentityValueChangedHandler}
                        kilometerChanged={this.insuredCarKilometerChangedHandler}
                        editState={this.state} />
                </TabContent>

                {!this.state.isFirstTab && (
                    <Button
                        className={'float-left'}
                        width={120}
                        text="Geri"
                        style={{ backgroundColor: '#c5bfbf5c' }}
                        stylingMode="contained"
                        onClick={this.prevTab}
                    />
                )}

                {!this.state.isLastTab && (
                    <Button
                        width={120}
                        className={'float-right'}
                        text="İleri"
                        type="success"
                        stylingMode="contained"
                        onClick={this.nextTab}
                    />
                )}
                <Button
                    disabled={this.state.loadIndicatorVisible}
                    id={'button'}
                    className={'float-right'}
                    width={180}
                    height={40}
                    onClick={this.onSaveClick}>
                    <LoadIndicator className={'button-indicator'} visible={this.state.loadIndicatorVisible} />
                    <span className={'dx-button-text'}>Kaydet</span>
                </Button>

            </div >

        );
    }

    workmanshipItemsChangedHandler(e) {
        this.workmanshipItems = e;
    }

    async onSaveClick() {
        this.setState({ loadIndicatorVisible: true });
        let report = this.state;
        report.workmanshipItems = this.workmanshipItems;
        report.reportTypeId = this.props.reportType;
        report.reportRequirer = this.props.reportRequirer;

        const addReportResponse = report.id ? await httpPut('reports/' + report.id, report) : await httpPost('reports', report);

        this.setState({ loadIndicatorVisible: false });

        if (addReportResponse.isSuccess) {
            const responseData = addReportResponse.response;
            const blob = getBlobFromFile(responseData.file);
            FileSaver(blob, responseData.fileName);
            this.setState({ id: responseData.id, number: responseData.number });
        }
    }
}
import React from 'react';
import getLocalStorageData from '../../utils/local-storage-manager';
import { Redirect } from 'react-router-dom';
import createStore from '../../utils/create-store';
import DataGrid, { FilterRow, Paging, Column, HeaderFilter, Pager } from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import httpGet from '../../utils/http-get';
import httpDelete from '../../utils/http-delete';
import notify from 'devextreme/ui/notify';
import UserComponent from './user';


const custOpDescriptions = {
    between: "arasında", contains: "İçerir", equal: "Eşittir",
    greaterThan: "Büyüktür", startsWith: "Başlayan", endsWith: "Biten", notContains: "İçermez", notEqual: "Eşit Değildir"
};

const position = { of: '#grid' };


export default class extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            user: null,
            dataSource: createStore('users')
        }
    }

    render() {
        const role = getLocalStorageData('role');

        if (role !== 'Administrator') {
            return (<Redirect to="/reports" />);
        }
        if (this.state.user) {
            return (
                <UserComponent
                    user={this.state.user}
                    reloadPageCallback={this.reloadPage}>
                </UserComponent>
            );
        }

        return (
            <div id="grid">

                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loading}
                    message="Yükleniyor..."
                />

                <DataGrid dataSource={this.state.dataSource}
                    showBorders={true}
                    remoteOperations={true}
                    wordWrapEnabled={true}
                    showInfo={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}>

                    <Paging pageSize="20" />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <FilterRow visible={true} resetOperationText="Temizle" operationDescriptions={custOpDescriptions} />
                    <Pager showPageSizeSelector={false} showNavigationButtons={true} visible={true} showInfo={true} infoText="Sayfa #{0}. Toplam: {1} ({2} Kayıt)" />


                    <Column dataField="userName" caption="Kullanıcı Adı" />
                    <Column dataField="email" caption="Email" />
                    <Column dataField="name" caption="İsim" />
                    <Column dataField="role" caption="Rol" />

                    <Column caption="İşlemler" type="buttons" width={110}
                        buttons={[{
                            text: 'Sil',
                            onClick: this.remove
                        },
                        {
                            text: 'Düzenle',
                            onClick: this.editUser
                        }]} />
                </DataGrid>
            </div>
        );
    }

    editUser = async (e) => {
        this.setState({ loading: true });

        const result = await httpGet('users/' + e.row.data.id);

        this.setState({ loading: false });

        if (result.isSuccess) {
            const user = result.response;

            this.setState({ user: user });
        }
    }

    remove = async (e) => {
        this.setState({ loading: true });

        const result = await httpDelete('users/' + e.row.data.id);

        this.setState({ loading: false });

        if (result.isSuccess) {
            notify('kayıt silinmiştir!', 'success', 1000);
            this.setState({ dataSource: createStore('users') })
        }
    }

    reloadPage = () => {
        this.setState({ user: null });
    }
}

import React from 'react';
import { Row, Col } from 'reactstrap';
import FormGroupComponent from '../common/form-group';
import Validator, { RequiredRule, PatternRule, EmailRule } from 'devextreme-react/validator';
import { CheckBox, TextBox, SelectBox, LoadIndicator, Button, ValidationGroup } from 'devextreme-react';
import httpPut from '../../utils/http-put';
import httpPost from '../../utils/http-post';


const roles = [
    {
        'Name': 'Normal Kullanıcı',
        'Value': 'User'
    },
    {
        'Name': 'Admin',
        'Value': 'Administrator'
    }
];

const passwordPattern = '(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.*[0-9])(?=.*[a-z])(?=.{8,})';

export default class extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.user ? this.props.user.id : null,
            username: null,
            ifUpdatePassword: false,
            password: null,
            name: this.props.user ? this.props.user.name : null,
            email: this.props.user ? this.props.user.email : null,
            role: this.props.user ? this.props.user.role : null,
            loadIndicatorVisible: false
        }
    }

    usernameChanged = (e) => {
        this.setState({ username: e.value });
    }

    ifUpdatePasswordChanged = (e) => {
        this.setState({ ifUpdatePassword: e.value });
    }

    passwordChanged = (e) => {
        this.setState({ password: e.value });
    }

    nameChanged = (e) => {
        this.setState({ name: e.value });
    }

    emailChanged = (e) => {
        this.setState({ email: e.value });
    }

    roleChanged = (e) => {
        this.setState({ role: e.value });
    }


    render() {
        return (

            <div className={'p-3'} style={{ fontWeight: 'bold' }}>
                <h2>{this.state.id ? 'Kullanıcı Düzenle' : 'Yeni Kullanıcı'}</h2>

                <div className={'dx-card responsive-paddings'}>
                    <Row>
                        <Col md={3}></Col>
                        <Col md={6}>

                            <ValidationGroup>
                                {!this.state.id && (
                                    <FormGroupComponent label={'Kullanıcı Adı: '}>
                                        <TextBox onValueChanged={this.usernameChanged}
                                            value={this.state.username} >
                                            <Validator>
                                                <RequiredRule message={'Zorunlu alan'} />
                                            </Validator>
                                        </TextBox>
                                    </FormGroupComponent>
                                )}
                                {this.state.id && (

                                    <FormGroupComponent label={'Şifre Değiştir: '}>
                                        <CheckBox onValueChanged={this.ifUpdatePasswordChanged}
                                            value={this.state.ifUpdatePassword} />
                                    </FormGroupComponent>
                                )}
                                {(this.state.ifUpdatePassword || !this.state.id) && (

                                    <FormGroupComponent label={'Şifre: '}>
                                        <TextBox onValueChanged={this.passwordChanged}
                                            mode="password" >
                                            <Validator>
                                                <RequiredRule message={'Zorunlu alan'} />
                                                <PatternRule
                                                    message="En az 8 karakter. Bükük harf - Küçük Harf - Rakam ve özel karakter içermelidir."
                                                    pattern={passwordPattern}
                                                />
                                            </Validator>
                                        </TextBox>
                                    </FormGroupComponent>
                                )}

                                <FormGroupComponent label={'İsim: '}>
                                    <TextBox onValueChanged={this.nameChanged}
                                        value={this.state.name} >
                                        <Validator>
                                            <RequiredRule message={'Zorunlu alan'} />
                                        </Validator>
                                    </TextBox>
                                </FormGroupComponent>


                                <FormGroupComponent label={'E-Posta: '}>
                                    <TextBox onValueChanged={this.emailChanged}
                                        value={this.state.email} >
                                        <Validator>
                                            <EmailRule message={'E-Posta valid Değil'} />
                                        </Validator>
                                    </TextBox>
                                </FormGroupComponent>


                                <FormGroupComponent label={'Rol: '}>
                                    <SelectBox dataSource={roles}
                                        displayExpr="Name"
                                        valueExpr="Value"
                                        value={this.state.role}
                                        onValueChanged={this.roleChanged} >
                                        <Validator>
                                            <RequiredRule message={'Zorunlu alan'} />
                                        </Validator>
                                    </SelectBox>
                                </FormGroupComponent>

                                <Button
                                    disabled={this.state.loadIndicatorVisible}
                                    id={'button'}
                                    className={'float-right'}
                                    width={180}
                                    height={40}
                                    onClick={this.onSaveClick}>
                                    <LoadIndicator className={'button-indicator'} visible={this.state.loadIndicatorVisible} />
                                    <span className={'dx-button-text'}>Kaydet</span>
                                </Button>
                            </ValidationGroup>
                        </Col>
                    </Row>
                </div>
            </div>

        )
    }

    onSaveClick = async (args) => {
        if (!args.validationGroup.validate().isValid) {
            return;
        }
        this.setState({ loadIndicatorVisible: true });

        const model = {
            id: this.state.id,
            username: this.state.username,
            ifUpdatePassword: this.state.ifUpdatePassword,
            password: this.state.password,
            name: this.state.name,
            email: this.state.email,
            role: this.state.role
        };

        const saveUserReponse = model.id ? await httpPut('users/' + model.id, model, true) : await httpPost('users', model, true);

        this.setState({ loadIndicatorVisible: false });

        if (saveUserReponse.isSuccess) {
            if (model.id) {
                this.props.reloadPageCallback();
            } else {
                window.location.href = '/users';
            }
        }
    }
}

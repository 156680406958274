import ReportsComponent from '../components/reports/data';
import ReportTypeSelectionComponent from '../components/reports/report-type-selection';
import VehiclePartsComponent from '../components/vehicle-parts/data';
import UsersComponent from '../components/users/data';
import UserComponent from '../components/users/user';

export default [
  {
    path: '/reports',
    component: ReportsComponent
  },
  {
    path: '/report',
    component: ReportTypeSelectionComponent
  },
  {
    path: '/vehicle-parts',
    component: VehiclePartsComponent
  },
  {
    path: '/users',
    component: UsersComponent
  },
  {
    path: '/user',
    component: UserComponent
  }
];

import errorMessage from "./notification";
import getLocalStorageData from './local-storage-manager';
import handleResponse from "./handle-response";

export default async function httpGet(url) {

    const token = getLocalStorageData('token');

    const data = await fetch(process.env.REACT_APP_BASE_URL + url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token ? ('Bearer ' + token) : ''
        }
    }).catch((err) => {
        console.log(err.message);
        errorMessage('Sunucu Hatası!');
    });

    return await handleResponse(data);
}
import React from 'react';

import { TabPane, Row, Col, Table } from 'reactstrap';
import '../report.css';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import FormGroupComponent from '../../common/form-group';
import { NumberBox } from 'devextreme-react';
import Button from 'devextreme-react/button';

export default class WorkmanshipTabComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      changedPartId: null,
      fixedPartId: null,

      partCode: null,
      partName: null,
      partType: null,
      partCount: null,
      partPrice: null,
      partDiscount: null,

      fixedPartType: null,
      fixedPartCount: null,

      totalParts: 0,
      totalDetails: 0,
      groupedTotals: [],

    };

    this.items = this.props.workmanshipItems;

    this.workmanshipItemDetails = [];

    setTimeout(() => this.calculateTotals());
  }

  render() {
    return (
      <TabPane tabId="workmanship-tab">

        <Row className={'mb-2 workmanship-group'}>

          <Col md={6}>
            <FormGroupComponent label={'ÇEKECİ ÜCRETİ'}>
              <NumberBox
                value={this.props.editState.towTruckPrice}
                onValueChanged={this.props.towTruckPriceChanged} />
            </FormGroupComponent>
          </Col>
        </Row>

        <Row className={'mb-2 workmanship-group'}>

          <Col md={12}>
            <h3>
              Değişim
            </h3>
          </Col>
          <Col md={6}>
            <FormGroupComponent label={'PARÇA KODU'}>
              <TextBox value={this.state.partCode}
                onValueChanged={this.partCodeChanged} />
            </FormGroupComponent>
          </Col>

          <Col md={6}>
            <FormGroupComponent label={'PARÇA ADI'}>
              <TextBox value={this.state.partName}
                onValueChanged={this.partNameChanged} />
            </FormGroupComponent>
          </Col>

          <Col md={6} key={1}>
            <FormGroupComponent label={'PARÇA TÜRÜ'}>
              <SelectBox items={['ORJ', 'EŞ DEĞER']}
                value={this.state.partType}
                onValueChanged={this.partTypeChanged} />
            </FormGroupComponent>
          </Col>

          <Col md={6} key={3}>
            <FormGroupComponent label={'PARÇA FİYATI'}>
              <NumberBox value={this.state.partPrice}
                onValueChanged={this.partPriceChanged} />
            </FormGroupComponent>
          </Col>

          <Col md={6} key={2}>
            <FormGroupComponent label={'PARÇA ADEDİ'}>
              <NumberBox value={this.state.partCount}
                onValueChanged={this.partCountChanged} />
            </FormGroupComponent>
          </Col>

          <Col md={6} key={4}>
            <FormGroupComponent label={'PARÇA İSKONTO'}>
              <TextBox value={this.state.partDiscount}
                onValueChanged={this.partDiscountChanged} />
            </FormGroupComponent>
          </Col>

          <Col md={12}>
            <Button className={'float-right'}
              icon={this.state.changedPartId != null ? 'pulldown' : 'plus'}
              text={this.state.changedPartId != null ? 'Güncelle' : 'Ekle'}
              onClick={this.addOrUpdateChangedPart} />
            <Button
              icon="refresh"
              text="Temizle"
              onClick={this.clearChangeForm} />
          </Col>

        </Row>

        <Row className={'mb-2 workmanship-group'}>
          <Col md={12}>
            <h3>
              Onarım
            </h3>
          </Col>
          <Col md={3}>
            <FormGroupComponent label={'Onarım'}>

              <TextBox value={this.state.fixedPartType}
                onValueChanged={this.fixedPartTypeChanged} />

            </FormGroupComponent>
            <br />

          </Col>
          <Col md={3}>
            <FormGroupComponent label={'Adet'}>

              <NumberBox value={this.state.fixedPartCount}
                onValueChanged={this.fixedPartCountChanged}
                onEnterKey={this.populateFixParts} />

            </FormGroupComponent>
            <br />

          </Col>
          <Col md={1}>
            <Button icon="chevrondoubleright"
              onClick={this.populateFixParts} />
          </Col>
          <Col md={5}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">İŞÇİLİK DETAYI</th>
                  <th scope="col">Parça adı</th>
                  <th scope="col">FİYATI</th>
                  <th scope="col">SİL</th>
                </tr>
              </thead>
              <tbody>
                {this.workmanshipItemDetails.map((subItem, subKey) =>
                  <tr key={subKey + 1}>
                    <th scope="row">{subKey + 1}</th>
                    <td>{subItem.type}</td>
                    <td><TextBox defaultValue={subItem.name} onValueChanged={(e) => this.updateDetailName(subKey, e)} /></td>
                    <td><NumberBox defaultValue={subItem.price} onValueChanged={(e) => this.updateDetailPrice(subKey, e)} /></td>
                    <td>
                      <a href="javasrcipt:;" onClick={() => this.deleteItemDetail(subKey)}>Sil</a>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
          <Col md={12}>
            <Button className={'float-right'}
              icon={this.state.fixedPartId != null ? 'pulldown' : 'plus'}
              text={this.state.fixedPartId != null ? 'Güncelle' : 'Ekle'}
              onClick={this.addOrUpdateFixedPart} />
            <Button
              icon="refresh"
              text="Temizle"
              onClick={this.clearFixForm} />
          </Col>
        </Row>

        <Row>
          <Col xs>
            <Table bordered size="sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">PARÇA DETAYI</th>
                  <th scope="col">PARÇA KODU</th>
                  <th scope="col">PARÇA ADI</th>
                  <th scope="col">PARÇA TÜRÜ</th>
                  <th scope="col">PARÇA ADEDİ</th>
                  <th scope="col">PARÇA FİYATI</th>
                  <th scope="col">PARÇA İSKONTO</th>
                  <th scope="col">PARÇA İŞÇİLİK</th>
                  <th scope="col">PARÇA İŞÇİLİK DETAYI</th>
                  <th scope="col">SİL</th>
                </tr>
              </thead>
              <tbody>
                {this.items.map((item, key) =>
                  <tr key={key + 1}>
                    <th scope="row">{key + 1}</th>
                    <td>{item.operationType}</td>
                    <td>{item.partCode}</td>
                    <td>{item.partName}</td>
                    <td>{item.partType}</td>
                    <td>{item.partCount}</td>
                    <td>{item.partPrice}</td>
                    <td>{item.partDiscount}</td>
                    <td>
                      {item.hasWorkmanship ? 'VAR' : 'YOK'}
                    </td>
                    <td>
                      {item.hasWorkmanship && (
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">İŞÇİLİK DETAYI</th>
                              <th scope="col">İŞÇİLİK kATEGORİ</th>
                              <th scope="col">İŞÇİLİK FİYATI</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.workmanshipItemDetails.map((subItem, subKey) =>
                              <tr key={subKey + 1}>
                                <th scope="row">{subKey + 1}</th>
                                <td>{subItem.name}</td>
                                <td>{subItem.type}</td>
                                <td>{subItem.price}</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      )}
                    </td>
                    <td>
                      <button onClick={() => this.bindUpdateItem(key)}>Düzenle</button>
                    </td>
                    <td>
                      <button onClick={() => this.deleteItem(key)}>Sil</button>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

          </Col>
        </Row>

        <Row>
          <Col>
            Parça Toplam: {this.state.totalParts}
            <br />
            Parça Detayı Toplam: {this.state.totalDetails}
            <br />
          </Col>
          <Col>
            {this.state.groupedTotals.map((item, key) =>
              <p key={key}>{item.category} : {item.price}</p>
            )}
          </Col>
        </Row>

      </TabPane>

    );
  }

  //#region Events
  partCodeChanged = (e) => {
    this.setState({ partCode: e.value });
  }

  partNameChanged = (e) => {
    this.setState({ partName: e.value });
  }

  partTypeChanged = (e) => {
    this.setState({ partType: e.value });
  }

  partCountChanged = (e) => {
    this.setState({ partCount: e.value });
  }

  partPriceChanged = (e) => {
    this.setState({ partPrice: e.value });
  }

  partDiscountChanged = (e) => {
    this.setState({ partDiscount: e.value });
  }

  vehiclePartChanged = (e) => {
    this.setState({ vehiclePart: e.value });
  }

  fixedPartTypeChanged = (e) => {
    this.setState({ fixedPartType: e.value });
  }

  fixedPartCountChanged = (e) => {
    this.setState({ fixedPartCount: e.value });
  }

  //#endregion

  bindUpdateItem = (index) => {

    if (this.items[index].hasWorkmanship === true) {
      this.workmanshipItemDetails = this.items[index].workmanshipItemDetails;
    }

    if (this.items[index].operationTypeId === 1) {
      this.setState({
        changedPartId: index,
        partCode: this.items[index].partCode,
        partName: this.items[index].partName,
        partType: this.items[index].partType,
        partCount: this.items[index].partCount,
        partPrice: this.items[index].partPrice,
        partDiscount: this.items[index].partDiscount,
        hasWorkmanship: this.items[index].hasWorkmanship
      });
    } else {
      this.setState({
        fixedPartId: index
      });
    }

  }

  //#region ChangePart Form
  clearChangeForm = () => {
    this.setState({
      changedPartId: null,
      partCode: null,
      partName: null,
      partType: null,
      partCount: null,
      partPrice: null,
      partDiscount: null
    });
  }

  addOrUpdateChangedPart = () => {
    if (this.state.changedPartId !== null) {
      this.items[this.state.changedPartId].partCode = this.state.partCode;
      this.items[this.state.changedPartId].partName = this.state.partName;
      this.items[this.state.changedPartId].partType = this.state.partType;
      this.items[this.state.changedPartId].partCount = this.state.partCount;
      this.items[this.state.changedPartId].partPrice = this.state.partPrice;
      this.items[this.state.changedPartId].partDiscount = this.state.partDiscount;
    } else {
      const toInsertIndex = this.items.findIndex(o => {
        return o.operationTypeId === 2;
      });

      const itemToAdd = {
        operationType: 'Değişim',
        operationTypeId: 1,
        partCode: this.state.partCode,
        partName: this.state.partName,
        partType: this.state.partType,
        partCount: this.state.partCount,
        partPrice: this.state.partPrice,
        partDiscount: this.state.partDiscount,
        hasWorkmanship: false
      };
      if (toInsertIndex !== -1) {
        this.items.splice(toInsertIndex, 0, itemToAdd);
      } else {
        this.items.push(itemToAdd);
      }

    }

    this.clearChangeForm();

    this.props.workmanshipCallback(this.items);

    this.calculateTotals();
  }

  calculateTotals() {
    if (this.items.length > 0) {
      const totalParts = this.items.reduce((a, b) => a + (b.partPrice || 0), 0);

      const totalPartsFormatted = new Intl.NumberFormat('tr-TR').format(totalParts);

      const itemsHaveWorkmanship = this.items.filter(x => x.hasWorkmanship === true);

      const totalDetails = itemsHaveWorkmanship.reduce((a, b) => a + (b.workmanshipItemDetails.reduce((a1, b1) => a1 + b1.price, 0)), 0);

      const totalDetailsFormatted = new Intl.NumberFormat('tr-TR').format(totalDetails);

      let groupedTotals = [];

      if (itemsHaveWorkmanship.length > 0) {
        const flatItemDetails = itemsHaveWorkmanship.map(p => p.workmanshipItemDetails)
          .reduce(function (a, b) { return a.concat(b); });

        const groupedItemDetails = flatItemDetails.reduce((r, a) => {
          r[a.type] = [...r[a.type] || [], a];
          return r;
        }, {});


        for (let [key, value] of Object.entries(groupedItemDetails)) {
          const totalCategory = value.reduce((a, b) => a + (b.price || 0), 0);
          const totalCategoryFormatted = new Intl.NumberFormat('tr-TR').format(totalCategory);
          groupedTotals.push({ category: key, price: totalCategoryFormatted });
        }
      }

      this.setState({ totalParts: totalPartsFormatted, totalDetails: totalDetailsFormatted, groupedTotals: groupedTotals });
    }
  }



  deleteItem(index) {
    this.items.splice(index, 1);

    this.setState({ changedPartId: null, fixedPartId: null });

    this.props.workmanshipCallback(this.items);

    this.calculateTotals();
  }
  //#endregion

  clearFixForm = () => {
    this.workmanshipItemDetails = [];

    this.setState({
      fixedPartId: null,
      fixedPartType: null,
      fixedPartCount: null
    });
  }

  populateFixParts = () => {

    this.workmanshipItemDetails.push(...Array.from(Array(this.state.fixedPartCount), () => {
      return { type: this.state.fixedPartType }
    }));

    this.setState({
      fixedPartType: null,
      fixedPartCount: null
    });
  }

  updateDetailName = (index, e) => {
    this.workmanshipItemDetails[index].name = e.value;
  }

  updateDetailPrice = (index, e) => {
    this.workmanshipItemDetails[index].price = e.value;
  }

  deleteItemDetail = (index) => {
    this.workmanshipItemDetails.splice(index, 1);

    this.setState({});
  }

  addOrUpdateFixedPart = () => {
    if (this.state.fixedPartId !== null) {
      this.items[this.state.fixedPartId].workmanshipItemDetails = this.workmanshipItemDetails;
    } else {
      this.items.push({
        operationType: 'Onarım',
        operationTypeId: 2,
        hasWorkmanship: true,
        workmanshipItemDetails: this.workmanshipItemDetails
      });

      //this.items = this.items.sort((n, n_1) => n.operationTypeId > n_1.operationTypeId ? 1 : -1);
    }

    this.clearFixForm();

    this.props.workmanshipCallback(this.items);

    this.calculateTotals();
  }

}
import React from 'react';
import Button from 'devextreme-react/button';
import { Row, Col } from 'reactstrap';

import ReportPage from './report';
import FormGroupComponent from '../common/form-group';
import TextBox from 'devextreme-react/text-box';

export default class extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            reportType: 0,
            reportRequirer: ''
        }

        this.reportRequirerChanged = this.reportRequirerChangedHandler.bind(this);
    }

    render() {
        return (
            <div className={'p-3'}  style={{fontWeight: 'bold'}}>
                <h2>{this.state.reportType === 0 ? 'Rapor Tipi' : 'Yeni Rapor'}</h2>

                <div className={'dx-card responsive-paddings'}>
                    {this.state.reportType > 0 && (
                        <ReportPage reportType={this.state.reportType} reportRequirer={this.state.reportRequirer} editState={{workmanshipItems: []}}></ReportPage>
                    )}
                    {this.state.reportType === 0 && (
                        <div>
                            <Row>
                                <Col xs>
                                    <FormGroupComponent label={'Raporu Talep eden: '}>
                                        <TextBox onValueChanged={this.reportRequirerChanged} />
                                    </FormGroupComponent>
                                </Col>

                            </Row>
                            <Row>
                                <Col xs>
                                    <Button
                                        text="Trafik Hasar"
                                        width="100%"
                                        height={80}
                                        icon="icons/1.png"
                                        onClick={() => this.reportTypeChangedHandler(1)}>
                                    </Button>
                                </Col>
                                <Col xs>
                                    <Button
                                        text="Kasko Hasar"
                                        width="100%"
                                        height={80}
                                        icon="icons/2.png"
                                        onClick={() => this.reportTypeChangedHandler(2)}>
                                    </Button>
                                </Col>
                                <Col xs>
                                    <Button
                                        text="Kusur Tespit"
                                        width="100%"
                                        height={80}
                                        icon="icons/3.png"
                                        onClick={() => this.reportTypeChangedHandler(3)}>
                                    </Button>
                                </Col>
                            </Row>
                        </div>


                    )}
                </div>
            </div>
        );
    }

    reportTypeChangedHandler(val) {
        this.setState({ reportType: val });
    }

    reportRequirerChangedHandler(e) {
        this.setState({ reportRequirer: e.value })
    }

}
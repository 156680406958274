import errorMessage from "./notification";
import getLocalStorageData from '../utils/local-storage-manager';
import handleResponse from "./handle-response";

export default async function httpPut(url, payload, withoutResponseBody = false) {

    const token = getLocalStorageData('token');

    const data = await fetch(process.env.REACT_APP_BASE_URL + url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token ? ('Bearer ' + token) : ''
        },
        body: JSON.stringify(payload),
    }).catch((err) => {
        console.log(err.message);
        errorMessage('Sunucu Hatası!');
    });

    return await handleResponse(data, withoutResponseBody);
}
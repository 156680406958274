import React from 'react';

import TextBox from 'devextreme-react/text-box';
import SelectBox from 'devextreme-react/select-box';
import { TabPane, Row, Col } from 'reactstrap';
import '../report.css';
import FormGroupComponent from '../../common/form-group';

export default class InsuredCarDriverInfoTabComponent extends React.Component {

    render() {
        return (
            <TabPane tabId="insured-car-driver-info-tab">
                <Row>
                    <Col xs>
                        <FormGroupComponent label={'KUSUR ORANI'}>
                            <SelectBox items={[100, 75, 50, 25, 0]}
                                value={this.props.editState.insuredCarDriverRatio}
                                onValueChanged={this.props.ratioChanged} />
                        </FormGroupComponent>

                        <FormGroupComponent label={'SÜRÜCÜ ADI SOYADI'}>
                            <TextBox
                                value={this.props.editState.insuredCarDriverName}
                                onValueChanged={this.props.nameChanged} />
                        </FormGroupComponent>

                        <FormGroupComponent label={'SINIFI'}>
                            <TextBox
                                value={this.props.editState.insuredCarDriverClass}
                                onValueChanged={this.props.classChanged} />
                        </FormGroupComponent>



                    </Col>
                    <Col xs>

                        <FormGroupComponent label={'SÜRÜCÜ KİMLİK TİPİ/DEĞERİ'}>
                            <TextBox
                                value={this.props.editState.insuredCarDriverIdentity}
                                onValueChanged={this.props.identityChanged} />
                        </FormGroupComponent>

                        <FormGroupComponent label={'BELGE NO'}>
                            <TextBox
                                value={this.props.editState.insuredCarDriverDocument}
                                onValueChanged={this.props.documentChanged} />
                        </FormGroupComponent>

                        <FormGroupComponent label={'ALKOL DURUMU'}>
                            <SelectBox
                                value={this.props.editState.insuredCarDriverAlcohol}
                                items={['VAR', 'YOK']} onValueChanged={this.props.alcoholChanged} />
                        </FormGroupComponent>

                        {this.props.editState.insuredCarDriverAlcohol === 'VAR' && (
                            <FormGroupComponent label={'PROMİL'}>
                                <TextBox
                                    value={this.props.editState.insuredCarDriverPromille}
                                    onValueChanged={this.props.promilleChanged} />
                            </FormGroupComponent>
                        )}

                    </Col>
                </Row>
            </TabPane>

        );
    }
}
import errorMessage from "./notification";

const HttpStatusCode = {
    OK: 200,
    BadRequest: 400,
    Unauthorized: 401,
    Forbidden: 403
}

export default async function handleResponse(response, wihoutBody = false) {

    if (response && response.status === HttpStatusCode.OK) {
        if (wihoutBody) {
            return { isSuccess: true, response: null };
        }
        const responseBody = await response.json();
        return { isSuccess: true, response: responseBody };
    }

    if (response && response.status === HttpStatusCode.BadRequest) {
        const responseBody = await response.json();
        errorMessage(responseBody.message);
        return { isSuccess: false, response: null };
    }

    if (response && (response.status === HttpStatusCode.Unauthorized || response.status === HttpStatusCode.Forbidden)) {
        localStorage.removeItem('data');

        window.location.href = "/";
    }

    return { isSuccess: false, response: null };
}
import React from 'react';

import TextBox from 'devextreme-react/text-box';
import { TabPane, Row, Col } from 'reactstrap';
import '../report.css';
import FormGroupComponent from '../../common/form-group';

export default class ServiceInfoTabComponent extends React.Component {

      render() {
            return (
                  <TabPane tabId="service-info-tab">
                        <Row>
                              <Col xs>
                                    <FormGroupComponent label={'SERVİS ADI'}>
                                          <TextBox 
                                          value={this.props.editState.serviceName}
                                          onValueChanged={this.props.serviceNameChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'SERVİS TELEFONU'}>
                                          <TextBox 
                                          value={this.props.editState.serviceNumber}
                                          onValueChanged={this.props.serviceNumberChanged} />
                                    </FormGroupComponent>

                              </Col>
                              <Col xs>
                                    <FormGroupComponent label={'VERGİ NUMARASI'}>
                                          <TextBox 
                                          value={this.props.editState.serviceTaxNumber}
                                          onValueChanged={this.props.serviceTaxNumberChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'ADRES'}>
                                          <TextBox 
                                          value={this.props.editState.serviceAddress}
                                          onValueChanged={this.props.serviceAddressChanged} />
                                    </FormGroupComponent>
                              </Col>
                        </Row>
                  </TabPane>

            );
      }
}
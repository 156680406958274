import React from 'react';

import { Row, Col, FormGroup, Label } from 'reactstrap';


export default class FormGroupComponent extends React.Component {

    render() {
        return (
            <FormGroup>
                <Row>
                    <Col xs="4">
                        <Label>{this.props.label}</Label>
                    </Col>
                    <Col xs="8">
                        {this.props.children}
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}
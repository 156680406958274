import React from 'react';
import TextBox from 'devextreme-react/text-box';
import SelectBox from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import { TabPane, Row, Col } from 'reactstrap';
import '../report.css';
import FormGroupComponent from '../../common/form-group';
import { damageShapes } from '../helpers/data';

export default class DamageInfoTabComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cekiciUcreti: false
        }

        this.cekiciUcretiChanged = this.cekiciUcretiChanged.bind(this);
    }

    render() {
        return (
            <TabPane tabId="damage-info-tab">
                <Row>
                    <Col xs>
                        <FormGroupComponent label={'HASAR TARİHİ'}>
                            <DateBox
                                value={this.props.editState.accidentDate}
                                type={'date'}
                                displayFormat={'dd.MM.yyyy'}
                                dateSerializationFormat={'yyyy-MM-dd'}
                                onValueChanged={this.props.accidentDateChanged} />
                        </FormGroupComponent>

                        <FormGroupComponent label={'KAZA İLÇESİ'}>
                            <TextBox
                                value={this.props.editState.accidentPlace}
                                onValueChanged={this.props.accidentPlaceChanged} />
                        </FormGroupComponent>

                        <FormGroupComponent label={'KAZA İLİ'}>
                            <TextBox
                                value={this.props.editState.accidentCityTown}
                                onValueChanged={this.props.accidentCityTownChanged} />
                        </FormGroupComponent>
                    </Col>

                    <Col xs>
                        <FormGroupComponent label={'KAZA SAATİ'}>
                            <DateBox
                                value={this.props.editState.accidentTime}
                                type={'time'}
                                displayFormat={'HH:mm'}
                                dateSerializationFormat={'HH:mm:ss'}
                                onValueChanged={this.props.accidentTimeChanged} />
                        </FormGroupComponent>

                        <FormGroupComponent label={'HASAR ŞEKLİ'}>
                            <SelectBox
                                value={this.props.editState.accidentType}
                                searchEnabled={true}
                                items={damageShapes}
                                onValueChanged={this.props.accidentTypeChanged} />
                        </FormGroupComponent>

                        <FormGroupComponent label={'TUTANAK TÜRÜ'}>
                            <SelectBox
                                value={this.props.editState.caseReportType}
                                searchEnabled={true}
                                items={['Polis Tutanağı','Anlaşmalı Tutanak','Jandarma Tutanağı','Beyan','İfade Tutanağı']}
                                onValueChanged={this.props.caseReportTypeChanged} />
                        </FormGroupComponent>
                    </Col>
                </Row>
            </TabPane>
        );
    }

    cekiciUcretiChanged(e) {
        this.setState({ cekiciUcreti: e.value });
    }


}
import React from 'react';

import TextBox from 'devextreme-react/text-box';
import SelectBox from 'devextreme-react/select-box';
import { TabPane, Row, Col } from 'reactstrap';
import '../report.css';
import FormGroupComponent from '../../common/form-group';

export default class InjuredCarDriverInfoTabComponent extends React.Component {

      render() {
            return (
                  <TabPane tabId="injured-car-driver-info-tab">
                        <Row>
                              <Col xs>
                                    <FormGroupComponent label={'KUSUR ORANI'}>
                                          <SelectBox
                                                value={this.props.editState.injuredCarDriverRatio}
                                                items={[100, 75, 50, 25, 0]} onValueChanged={this.props.ratioChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'TC KİMLİK NO'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarDriverIdentity}
                                                onValueChanged={this.props.identityChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'BELGE NO'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarDriverDocumentNo}
                                                onValueChanged={this.props.documentNoChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'AD SOYAD'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarDriverName}
                                                onValueChanged={this.props.nameChanged} />
                                    </FormGroupComponent>

                              </Col>
                              <Col xs>


                                    <FormGroupComponent label={'SINIFI'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarDriverClass}
                                                onValueChanged={this.props.classChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'YETERLİMİ/YETERSİZMİ'}>
                                          <SelectBox
                                                value={this.props.editState.injuredCarDriverSufficiency}
                                                items={['YETERLİ', 'YETERSİZ']} onValueChanged={this.props.sufficiencyChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'ALKOL DURUMU'}>
                                          <SelectBox
                                                value={this.props.editState.injuredCarDriverAlcoholStatus}
                                                items={['VAR', 'YOK']} onValueChanged={this.props.alcoholStatusChanged} />
                                    </FormGroupComponent>

                                    {this.props.editState.injuredCarDriverAlcoholStatus === 'VAR' && (
                                          <FormGroupComponent label={'PROMİL'}>
                                                <TextBox
                                                      value={this.props.editState.injuredCarDriverPromille}
                                                      onValueChanged={this.props.promilleChanged} />
                                          </FormGroupComponent>
                                    )}

                              </Col>
                        </Row>
                  </TabPane>

            );
      }
}
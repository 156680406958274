import React from 'react';

import TextArea from 'devextreme-react/text-area';
import { TabPane, Row, Col } from 'reactstrap';
import '../report.css';
import FormGroupComponent from '../../common/form-group';

export default class NotesTabComponent extends React.Component {

  render() {
    return (
      <TabPane tabId="notes-tab">
        <Row>
          <Col xs>


            <FormGroupComponent label={'EKSPER NOTU'}>
              <TextArea
                height={90} 
                value={this.props.editState.expertNote}
                onValueChanged={this.props.expertNoteChanged} />
            </FormGroupComponent>

            <FormGroupComponent label={'MUAFİYET NOTU'}>
              <TextArea
                height={90} 
                value={this.props.editState.exemptionNote}
                onValueChanged={this.props.exemptionNoteChanged} />
            </FormGroupComponent>

            <FormGroupComponent label={'HASARIN NİTELİĞİ VE ONARIM İLE İLGİLİ ÇALIŞMALAR'}>
              <TextArea
                height={90} 
                value={this.props.editState.accidentNote}
                onValueChanged={this.props.accidentNoteChanged} />
            </FormGroupComponent>

          </Col>
          <Col xs>

            <FormGroupComponent label={'HADİSE NOTU'}>
              <TextArea
                height={90} 
                value={this.props.editState.incidentNote}
                onValueChanged={this.props.incidentNoteChanged} />
            </FormGroupComponent>

            <FormGroupComponent label={'EKSİK / AŞKIN SİGORTA NOTU'}>
              <TextArea
                height={90} 
                value={this.props.editState.insuranceNote}
                onValueChanged={this.props.insuranceNoteChanged} />
            </FormGroupComponent>

            <FormGroupComponent label={'DİĞER'}>
              <TextArea
                height={90} 
                value={this.props.editState.otherNote}
                onValueChanged={this.props.otherNoteChanged} />
            </FormGroupComponent>

          </Col>
        </Row>
        <Row>
          <Col xs>

            <FormGroupComponent label={'NOTLAR'}>
              <TextArea
                height={90} 
                value={this.props.editState.notes}
                onValueChanged={this.props.notesChanged} />
            </FormGroupComponent>

          </Col>

        </Row>
      </TabPane>

    );
  }
}


import React from 'react';
import DataGrid, { FilterRow, Paging, Column, HeaderFilter, Pager } from 'devextreme-react/data-grid';
import ReportPage from './report';
import LoadPanel from 'devextreme-react/load-panel';
import FileSaver from 'file-saver';
import getBlobFromFile from '../../utils/blob-file';
import createStore from '../../utils/create-store';
import httpGet from '../../utils/http-get';


const dataSource = createStore('reports');

const custOpDescriptions = {
  between: "arasında", contains: "İçerir", equal: "Eşittir",
  greaterThan: "Büyüktür", startsWith: "Başlayan", endsWith: "Biten", notContains: "İçermez", notEqual: "Eşit Değildir"
};

const position = { of: '#grid' };

export default class extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      report: null,
      loading: false
    }

    this.downloadReport = this.downloadReport.bind(this);
    this.editReport = this.editReport.bind(this);
    this.reloadPage = this.reloadPage.bind(this);
  }

  render() {
    return (
      <div>
        {this.state.report && (
          <div className={'p-3'} style={{ fontWeight: 'bold' }}>
            <h2>Rapor Düzenleme</h2>
            <div className={'dx-card responsive-paddings'}>
              <ReportPage reportType={this.state.report.reportTypeId}
                reportRequirer={this.state.report.reportRequirer}
                editState={this.state.report}
                reloadPageCallback={this.reloadPage}></ReportPage>
            </div>
          </div>
        )}
        {!this.state.report && (
          <div id="grid">

            <LoadPanel
              shadingColor="rgba(0,0,0,0.4)"
              position={position}
              visible={this.state.loading}
              message="Yükleniyor..."
            />

            <DataGrid

              dataSource={dataSource}
              showBorders={true}
              remoteOperations={true}
              wordWrapEnabled={true}
              showInfo={true}
              showRowLines={true}
              rowAlternationEnabled={true}
            >

              <Paging pageSize="20" />
              <HeaderFilter visible={true} allowSearch={true} />
              <FilterRow visible={true} resetOperationText="Temizle" operationDescriptions={custOpDescriptions} />
              <Pager showPageSizeSelector={false} showNavigationButtons={true} visible={true} showInfo={true} infoText="Sayfa #{0}. Toplam: {1} ({2} Kayıt)" />


              <Column dataField="reportNumber" caption="Rapor Numarası" filterOperations={['=']} selectedFilterOperation='=' />
              <Column dataField="reportRequirer" caption="Raporu talep eden" />
              <Column dataField="reportType" caption="Rapor Tipi" />
              <Column dataField="injuredCarPlateNo" caption="Plaka Numarası" />
              <Column dataField="injuredCarName" caption="Araç Sahibi" />
              <Column dataField="createUser" caption="Raporu Oluşturan" />
              <Column dataField="createTime" caption="Rapor Oluşturma Tarihi" dataType="date" format="dd.MM.yyyy HH:mm" defaultSortIndex={1}
                defaultSortOrder="desc" />

              <Column caption="İşlemler" type="buttons" width={110}
                buttons={[{
                  text: 'İndir',
                  onClick: this.downloadReport
                },
                {
                  text: 'Düzenle',
                  onClick: this.editReport
                }]} />
            </DataGrid>
          </div>

        )}



      </div>
    );
  }

  async downloadReport(e) {
    this.setState({ loading: true });

    const fetchReport = await httpGet('reports/' + e.row.data.id + '/download');

    this.setState({ loading: false });

    if (fetchReport.isSuccess) {
      const report = fetchReport.response;
      const blob = getBlobFromFile(report.file);
      FileSaver(blob, report.fileName);
    }
  }

  async editReport(e) {
    this.setState({ loading: true });

    const editReportResponse = await httpGet('reports/' + e.row.data.id);

    this.setState({ loading: false });

    if (editReportResponse.isSuccess) {
      const report = editReportResponse.response;

      this.setState({ report: report });
    }
  }

  reloadPage = () => {
    this.setState({ report: null });
  }
};

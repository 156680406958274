import React from 'react';
import TextBox from 'devextreme-react/text-box';
import ValidationGroup from 'devextreme-react/validation-group';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import Button from 'devextreme-react/button';
import './login-form.scss';
import appInfo from '../../app/app-info';
import httpPost from '../../utils/http-post';
import LoadPanel from 'devextreme-react/load-panel';

const position = { of: '#login' };

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
      loading: false
    };
  }

  render() {
    const { login, password } = this.state;
    return (
      <div id="#login">
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loading}
          message="Yükleniyor..."
        />

        <ValidationGroup>
          <div className={'login-header'}>
            <div className={'title'}>{appInfo.title}</div>
            <div>Üye Girişi</div>
          </div>
          <div className={'dx-field'}>
            <TextBox
              value={login}
              onValueChanged={this.loginChanged}
              placeholder={'KULLANICI ADI'}
              width={'100%'}
            >
              <Validator>
                <RequiredRule message={'kULLANICI ADI ZORUNLU'} />
              </Validator>
            </TextBox>
          </div>
          <div className={'dx-field'}>
            <TextBox
              mode={'password'}
              value={password}
              onValueChanged={this.passwordChanged}
              placeholder={'ŞİFRE'}
              width={'100%'}
            >
              <Validator>
                <RequiredRule message={'ŞİFRE ZORUNLU'} />
              </Validator>
            </TextBox>
          </div>

          <div className={'dx-field'}>
            <Button
              type={'default'}
              text={'GİRİŞ'}
              onClick={this.onLoginClick}
              width={'100%'}
            />
          </div>
        </ValidationGroup>
      </div>

    );
  }

  loginChanged = e => {
    this.setState({ login: e.value });
  };

  passwordChanged = e => {
    this.setState({ password: e.value });
  };

  onLoginClick = async args => {
    if (!args.validationGroup.validate().isValid) {
      return;
    }

    const { login, password } = this.state;

    this.setState({ loading: true });

    var res = await httpPost('authentication', { username: login, password: password });

    this.setState({ loading: false });

    if (!res.isSuccess) {
      return;
    }

    this.props.onLoginClick({ username: login, token: res.response.token, role: res.response.role });

    args.validationGroup.reset();
  };
}

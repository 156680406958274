import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import getLocalStorageData from '../utils/local-storage-manager';

export default function createStore(url, onLoadingCallback = null) {

    return AspNetData.createStore({
        key: 'id',
        loadUrl: process.env.REACT_APP_BASE_URL + url,
        load: () => this.sendRequest(`${URL}/Orders`),
        onLoading: onLoadingCallback,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.headers = {
                'Authorization': 'Bearer ' + getLocalStorageData('token')
            };
        },
        onAjaxError: (e) => {
            if (e.xhr.status === 403 || e.xhr.status === 401) {
                localStorage.removeItem('data');

                window.location.href = "/";
            }
        }
    });
}
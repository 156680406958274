export const navigation = [
  {
    text: 'Raporlar',
    role: '',
    icon: 'paste',
    items: [
      {
        text: 'Yeni Rapor',
        path: '/report',
        icon: 'plus'
      },
      {
        text: 'Tüm raporlar',
        path: '/reports',
        icon: 'menu'
      },
      {
        text: 'İşçilik Detayı',
        path: '/vehicle-parts',
        icon: 'menu'
      }
    ]
  },
  {
    text: 'Kullanıcılar',
    role: 'Administrator',
    icon: 'user',
    items: [
      {
        text: 'Tüm Kullanıcılar',
        path: '/users',
        icon: 'menu'
      },
      {
        text: 'Yeni Kullanıcı',
        path: '/user',
        icon: 'plus'
      }
    ]
  }
];

import React from 'react';
import DataGrid, { FilterRow, Paging, Column, HeaderFilter, Pager } from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import httpDelete from '../../utils/http-delete';
import createStore from '../../utils/create-store';
import notify from 'devextreme/ui/notify';

const custOpDescriptions = {
    between: "arasında", contains: "İçerir", equal: "Eşittir",
    greaterThan: "Büyüktür", startsWith: "Başlayan", endsWith: "Biten", notContains: "İçermez", notEqual: "Eşit Değildir"
};

const position = { of: '#grid' };

export default class extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            dataSource: createStore('vehicle-parts')
        }

        this.remove = this.remove.bind(this);
    }

    render() {
        return (
            <div id="grid">

                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loading}
                    message="Yükleniyor..."
                />

                <DataGrid
                    dataSource={this.state.dataSource}
                    showBorders={true}
                    remoteOperations={true}
                    wordWrapEnabled={true}
                    showInfo={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                >

                    <Paging pageSize="20" />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <FilterRow visible={true} resetOperationText="Temizle" operationDescriptions={custOpDescriptions} />
                    <Pager showPageSizeSelector={false} showNavigationButtons={true} visible={true} showInfo={true} infoText="Sayfa #{0}. Toplam: {1} ({2} Kayıt)" />


                    <Column dataField="name" caption="Parça adı" />
                    <Column dataField="type" caption="Parça Tipi" />

                    <Column caption="İşlemler" type="buttons" width={110}
                        buttons={[{
                            text: 'Sil',
                            onClick: this.remove
                        }]} />
                </DataGrid>
            </div>
        );
    }

    async remove(e) {
        this.setState({ loading: true });

        const result = await httpDelete('vehicle-parts/' + e.row.data.id);

        this.setState({ loading: false });

        if (result.isSuccess) {
            notify('kayıt silinmiştir!', 'success', 1000);
            this.setState({ dataSource: createStore('vehicle-parts') })
        }
    }
};

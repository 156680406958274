import React from 'react';

import TextBox from 'devextreme-react/text-box';
import SelectBox from 'devextreme-react/select-box';
import { TabPane, Row, Col } from 'reactstrap';
import '../report.css';
import FormGroupComponent from '../../common/form-group';

export default class InjuredCarInfoTabComponent extends React.Component {

      render() {
            return (
                  <TabPane tabId="injured-car-info-tab">
                        <Row>
                              <Col xs>
                                    <FormGroupComponent label={'AD SOYAD'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarName}
                                                onValueChanged={this.props.nameChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'KİMLİK TİPİ'}>
                                          <SelectBox
                                                value={this.props.editState.injuredCarIdentityType}
                                                onValueChanged={this.props.identityTypeChanged} items={['Özel - Tüzel', 'TC Kimlik No', 'Vergi Kimlik No', 'Pasaport No', 'Yabancı Kimlik No', 'Kimliksiz']} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'MARKA ADI'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarBrandName}
                                                onValueChanged={this.props.brandNameChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'MODEL YILI'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarModelYear}
                                                onValueChanged={this.props.modelYearChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'ŞASİ NO'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarChassisNumber}
                                                onValueChanged={this.props.chassisNumberChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'PLAKA'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarPlateNo}
                                                onValueChanged={this.props.plateNoChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'ARAÇ KULLANIM ŞEKLİ'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarUsageType}
                                                onValueChanged={this.props.usageTypeChanged} />
                                    </FormGroupComponent>

                              </Col>
                              <Col xs>

                                    <FormGroupComponent label={'KİMLİK TİPİ DEĞERİ'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarIdentityValue}
                                                onValueChanged={this.props.identityValueChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'MODEL ADI'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarModelName}
                                                onValueChanged={this.props.modelNameChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'KM'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarKilometer}
                                                onValueChanged={this.props.kilometerChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'RUHSAT SERİ NO'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarLicenseSerialNo}
                                                onValueChanged={this.props.licenseSerialNoChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'TRAFİC TESCİL TARİHİ'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarTrafficRegisterDate}
                                                onValueChanged={this.props.trafficRegisterDateChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'MOTOR NO'}>
                                          <TextBox
                                                value={this.props.editState.injuredCarMotorNo}
                                                onValueChanged={this.props.motorNoChanged} />
                                    </FormGroupComponent>

                              </Col>
                        </Row>
                  </TabPane>

            );
      }
}

export function accidentDateChangedHandler(e) {
    this.setState({ accidentDate: e.value });
}

export function accidentPlaceChangedHandler(e) {
    this.setState({ accidentPlace: e.value });
}

export function accidentCityTownChangedHandler(e) {
    this.setState({ accidentCityTown: e.value });
}

export function accidentTimeChangedHandler(e) {
    this.setState({ accidentTime: e.value });
}

export function accidentTypeChangedHandler(e) {
    this.setState({ accidentType: e.value });
}

export function caseReportTypeChangedHandler(e) {
    this.setState({ caseReportType: e.value });
}

export function injuredCarNameChangedHandler(e) {
    this.setState({ injuredCarName: e.value });
}

export function injuredCarIdentityTypeChangedHandler(e) {
    this.setState({ injuredCarIdentityType: e.value });
}

export function injuredCarBrandNameChangedHandler(e) {
    this.setState({ injuredCarBrandName: e.value });
}

export function injuredCarModelYearChangedHandler(e) {
    this.setState({ injuredCarModelYear: e.value });
}

export function injuredCarChassisNumberChangedHandler(e) {
    this.setState({ injuredCarChassisNumber: e.value });
}

export function injuredCarPlateNoChangedHandler(e) {
    this.setState({ injuredCarPlateNo: e.value });
}

export function injuredCarUsageTypeChangedHandler(e) {
    this.setState({ injuredCarUsageType: e.value });
}

export function injuredCarIdentityValueChangedHandler(e) {
    this.setState({ injuredCarIdentityValue: e.value });
}

export function injuredCarModelNameChangedHandler(e) {
    this.setState({ injuredCarModelName: e.value });
}

export function injuredCarKilometerChangedHandler(e) {
    this.setState({ injuredCarKilometer: e.value });
}

export function injuredCarLicenseSerialNoChangedHandler(e) {
    this.setState({ injuredCarLicenseSerialNo: e.value });
}

export function injuredCarTrafficRegisterDateChangedHandler(e) {
    this.setState({ injuredCarTrafficRegisterDate: e.value });
}

export function injuredCarMotorNoChangedHandler(e) {
    this.setState({ injuredCarMotorNo: e.value });
}

export function injuredCarDriverRatioChangedHandler(e) {
    this.setState({ injuredCarDriverRatio: e.value });
}

export function injuredCarDriverIdentityChangedHandler(e) {
    this.setState({ injuredCarDriverIdentity: e.value });
}

export function injuredCarDriverDocumentNoChangedHandler(e) {
    this.setState({ injuredCarDriverDocumentNo: e.value });
}

export function injuredCarDriverNameChangedHandler(e) {
    this.setState({ injuredCarDriverName: e.value });
}

export function injuredCarDriverClassChangedHandler(e) {
    this.setState({ injuredCarDriverClass: e.value });
}

export function injuredCarDriverSufficiencyChangedHandler(e) {
    this.setState({ injuredCarDriverSufficiency: e.value });
}

export function injuredCarDriverAlcoholStatusChangedHandler(e) {
    this.setState({ injuredCarDriverAlcoholStatus: e.value });
}

export function injuredCarDriverPromilleChangedHandler(e) {
    this.setState({ injuredCarDriverPromille: e.value });
}

export function expertNoteChangedHandler(e) {
    this.setState({ expertNote: e.value });
}

export function exemptionNoteChangedHandler(e) {
    this.setState({ exemptionNote: e.value });
}

export function accidentNoteChangedHandler(e) {
    this.setState({ accidentNote: e.value });
}

export function incidentNoteChangedHandler(e) {
    this.setState({ incidentNote: e.value });
}

export function insuranceNoteChangedHandler(e) {
    this.setState({ insuranceNote: e.value });
}

export function otherNoteChangedHandler(e) {
    this.setState({ otherNote: e.value });
}

export function notesChangedHandler(e) {
    this.setState({ notes: e.value });
}


export function insuranceShapeChangedHandler(e) {
    this.setState({ insuranceShape: e.value });
}

export function agencyCodeChangedHandler(e) {
    this.setState({ agencyCode: e.value });
}

export function policyStartChangedHandler(e) {
    let policyEnd = new Date(e.value);
    policyEnd.setFullYear(new Date(e.value).getFullYear() + 1);
    this.setState({ policyStart: e.value, policyEnd: policyEnd });
}

export function policyNoChangedHandler(e) {
    this.setState({ policyNo: e.value });
}

export function insuranceCompanyChangedHandler(e) {
    this.setState({ insuranceCompany: e.value });
}



export function serviceNameChangedHandler(e) {
    this.setState({ serviceName: e.value });
}

export function serviceNumberChangedHandler(e) {
    this.setState({ serviceNumber: e.value });
}

export function serviceTaxNumberChangedHandler(e) {
    this.setState({ serviceTaxNumber: e.value });
}

export function serviceAddressChangedHandler(e) {
    this.setState({ serviceAddress: e.value });
}



export function insuredCarDriverRatioChangedHandler(e) {
    this.setState({ insuredCarDriverRatio: e.value });
}

export function insuredCarDriverNameChangedHandler(e) {
    this.setState({ insuredCarDriverName: e.value });
}

export function insuredCarDriverClassChangedHandler(e) {
    this.setState({ insuredCarDriverClass: e.value });
}

export function insuredCarDriverAlcoholChangedHandler(e) {
    this.setState({ insuredCarDriverAlcohol: e.value });
}

export function insuredCarDriverIdentityChangedHandler(e) {
    this.setState({ insuredCarDriverIdentity: e.value });
}

export function insuredCarDriverDocumentChangedHandler(e) {
    this.setState({ insuredCarDriverDocument: e.value });
}

export function insuredCarDriverPromilleChangedHandler(e) {
    this.setState({ insuredCarDriverPromille: e.value });
}

export function insuredCarBrandChangedHandler(e) {
    this.setState({ insuredCarBrand: e.value });
}

export function insuredCarModelYearChangedHandler(e) {
    this.setState({ insuredCarModelYear: e.value });
}

export function insuredCarPlateChangedHandler(e) {
    this.setState({ insuredCarPlate: e.value });
}

export function insuredCarChassisNoChangedHandler(e) {
    this.setState({ insuredCarChassisNo: e.value });
}

export function insuredCarCarOwnerChangedHandler(e) {
    this.setState({ insuredCarCarOwner: e.value });
}

export function insuredCarLicenseChangedHandler(e) {
    this.setState({ insuredCarLicense: e.value });
}

export function insuredCarModelNameChangedHandler(e) {
    this.setState({ insuredCarModelName: e.value });
}

export function insuredCarMotorNoChangedHandler(e) {
    this.setState({ insuredCarMotorNo: e.value });
}

export function insuredCarOutToTrafficDateChangedHandler(e) {
    this.setState({ insuredCarOutToTrafficDate: e.value });
}

export function insuredCarUsageTypeChangedHandler(e) {
    this.setState({ insuredCarUsageType: e.value });
}

export function insuredCarIdentityTypeChangedHandler(e) {
    this.setState({ insuredCarIdentityType: e.value });
}

export function insuredCarIdentityValueChangedHandler(e) {
    this.setState({ insuredCarIdentityValue: e.value });
}

export function insuredCarKilometerChangedHandler(e) {
    this.setState({ insuredCarKilometer: e.value });
}


export function expertPriceChangedHandler(e) {
    this.setState({ expertPrice: e.value });
}

export function towTruckPriceChangedHandler(e) {
    this.setState({ towTruckPrice: e.value });
}
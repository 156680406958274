export const damageShapes = [
    'Yangın Olmaksızın Maddi Zarar',
    'Yanma',
    'İnfilak',
    'Koltuk Yanığı (Sigara Hasarı)',
    'Araç Çalınması',
    'Aracın İç Parçalarının Çalınması',
    'Aracın Dış Parçalarının Çalınması',
    'Anahtar  Kaybı/Çalınması',
    'Far Kırılması',
    'İki Aracın Karıştığı Kaza',
    'Araç Harici Maddi Zarar',
    'İkiden Fazla Aracın Karıştığı Kaza (Zincirleme Kaza)',
    'Maddi Hasar',
    'Araç (Sadece Maddi Hasar )',
    'Fırtına Hasarı',
    'Dolu Hasarı',
    'Yıldırım Hasarı',
    'Sel/Su Baskını',
    'Deprem',
    'Hırsızlığa Teşebbüs Sonucu Araçta Meydana Gelen Hasarlar',
    'Araç Hasar Olmaksızın Maddi Hasar',
    'Zincirleme Hasar',
    'Yaralanma',
    'Sakatlık',
    'Kötü Niyetli ve Muziplikle Meydana Gelen Zarar',
    'Terörizm',
    'Buzlanma',
    'Taşınan Mal Hasarı (Sadece Taşınan Mal )',
    'Kisisel Esya',
    'Aracin Çalinip Bulunmasi',
    'Diger Araçlar ile Kaza /Çarpisma',
    'Baska Araçlara Çarpmaksizin Kaza',
    'Park Halinde Çarpilma',
    'Yabani Hayvan Hasari',
    'istiab Haddini Aşma',
    'Lastik Hasarı',
    'Tamirhane Adresi Verilemiyor',
    'Radyo Teyp/Navigasyon vb. Çalinmasi',
    'Araç Belgesi Kaybı/Çalınması',
    'Koltuk Yaniği (Sigara Hasarı)',
    'Isıdan Dolayı Kavrulma ve Kabarmalar',
    'Anahtar  Kaybi/Çalinmasi',
    'Akıllı mini onarım',
    'Değer Kaybı',
    'Tedavi',
    'Ölüm',
    'Sürekli Sakatlık',
    'Manevi Tazminat',
    'Cam Kırılması',
    'Diğer Araçlar İle Kaza Çarpışma',
    'Yanma/İnfilak (Kaza Olmadan )',
    'GLKHH',
    'Koltuk Yanığı (Sigara Yanığı)',
    'Deprem Hasarı',
    'Çığ Düşmesi',
    'Kar Ağırlığı',
    'Toprak Kayması',
    'Kaya Düşmesi',
    'Yanma (Terör Hariç)',
    'Hırsızlığa Teşebbüs Sonucu Araçta Meydana Gelen Zararlar',
    'Diğer Hasarlar',
    'Radyo Teyp Çalınması',
    'Cam Tamiri',
    'Anahtar ve Araç Belgesi kaybı/çalınması'
];

export const insuranceCompanies = [
    'AKDENİZ SİGORTA',
    'AKSİGORTA',
    'ALLIANZ SİGORTA',
    'ANADOLU SİGORTA',
    'ANKARA SİGORTA',
    'ATLAS SIGORTA',
    'ATRADIUS',
    'AXA SİGORTA',
    'BEREKET SİGORTA',
    'BNP CARDIF ELMNT',
    'CHUBB EUROPEAN',
    'COFACE',
    'CORPUS SİGORTA',
    'DOĞA SİGORTA',
    'DUBAİ STARR SİGORTA',
    'EGE SİGORTA',
    'EGS',
    'EMEKSIGORTA',
    'ERGO SİGORTA',
    'ETHICA SIGORTA',
    'EULER',
    'EUREKO SİGORTA',
    'G.I.C. DÜNYA SİGORTA',
    'GENERALİ SİGORTA',
    'GROUPAMA SİGORTA',
    'GULF SİGORTA',
    'GULF SİGORTA(ESKİ)',
    'GÜNEŞ SİGORTA',
    'GÜVEN SİGORTA',
    'HALK SİGORTA',
    'HDI SİGORTA',
    'HÜR SİGORTA',
    'INTER',
    'KAPİTAL SİGORTA',
    'KORU SİGORTA',
    'LIBERTY SİGORTA',
    'MAGDEBURGER',
    'MAPFRE SİGORTA',
    'MERKEZ SİGORTA',
    'NEOVA SİGORTA',
    'ORIENT SİGORTA',
    'PANDI SİGORTA',
    'QUICK SİGORTA',
    'RAY SİGORTA',
    'RUMELİ SİGORTA',
    'SANKO',
    'SOMPO SİGORTA',
    'TMTSIGORTA',
    'TÜRK NİPPON SİGORTA',
    'UNICO SİGORTA',
    'UNIVARSAL',
    'EGE SİGORTA',
    'YAPI KREDİ SİGORTA',
    'ZURICH SİGORTA',
    'ZİRAAT SİGORTA',
    'ŞEKER SİGORTA'
]
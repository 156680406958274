import React from 'react';

import TextBox from 'devextreme-react/text-box';
import SelectBox from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import { TabPane, Row, Col } from 'reactstrap';
import '../report.css';
import FormGroupComponent from '../../common/form-group';
import { insuranceCompanies } from '../helpers/data';

export default class InsurancePolicyInfoTabComponent extends React.Component {

      render() {
            return (
                  <TabPane tabId="insurance-policy-info-tab">
                        <Row>
                              <Col xs>
                                    <FormGroupComponent label={'SİGORTA ŞEKLİ'}>
                                          <TextBox
                                                value={this.props.upperState.insuranceShape}
                                                onValueChanged={this.props.insuranceShapeChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'ACENTE KODU'}>
                                          <TextBox
                                                value={this.props.upperState.agencyCode}
                                                onValueChanged={this.props.agencyCodeChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'POLİÇE BAŞLANGIÇ'}>
                                          <DateBox
                                                type={'date'}
                                                displayFormat={'dd.MM.yyyy'}
                                                dateSerializationFormat={'yyyy-MM-dd'}
                                                value={this.props.upperState.policyStart}
                                                onValueChanged={this.props.policyStartChanged} />
                                    </FormGroupComponent>

                              </Col>
                              <Col xs>
                                    <FormGroupComponent label={'POLİÇE NO'}>
                                          <TextBox
                                                value={this.props.upperState.policyNo}
                                                onValueChanged={this.props.policyNoChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'SİGORTA ŞİRKETİ'}>
                                          <SelectBox searchEnabled={true} items={insuranceCompanies}
                                                value={this.props.upperState.insuranceCompany}
                                                onValueChanged={this.props.insuranceCompanyChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'POLİÇE BİTİŞ'}>
                                          <DateBox
                                                type={'date'}
                                                displayFormat={'dd.MM.yyyy'}
                                                dateSerializationFormat={'yyyy-MM-dd'}
                                                disabled={true}
                                                value={this.props.upperState.policyEnd} />
                                    </FormGroupComponent>

                              </Col>
                        </Row>
                  </TabPane>

            );
      }
}
import React from 'react';

import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import { TabPane, Row, Col } from 'reactstrap';
import '../report.css';
import FormGroupComponent from '../../common/form-group';

export default class InsuredCarInfoTabComponent extends React.Component {

      render() {
            return (
                  <TabPane tabId="insured-car-info-tab">
                        <Row>
                              <Col xs>
                                    <FormGroupComponent label={'AD SOYAD'}>
                                          <TextBox
                                                value={this.props.editState.insuredCarCarOwner}
                                                onValueChanged={this.props.carOwnerChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'KİMLİK TİPİ'}>
                                          <SelectBox
                                                value={this.props.editState.insuredCarIdentityType}
                                                onValueChanged={this.props.identityTypeChanged} items={['Özel - Tüzel', 'TC Kimlik No', 'Vergi Kimlik No', 'Pasaport No', 'Yabancı Kimlik No', 'Kimliksiz']} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'MARKA ADI'}>
                                          <TextBox
                                                value={this.props.editState.insuredCarBrand}
                                                onValueChanged={this.props.brandChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'MODEL YILI'}>
                                          <TextBox
                                                value={this.props.editState.insuredCarModelYear}
                                                onValueChanged={this.props.modelYearChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'ŞASİ NO'}>
                                          <TextBox
                                                value={this.props.editState.insuredCarChassisNo}
                                                onValueChanged={this.props.chassisNoChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'PLAKA'}>
                                          <TextBox
                                                value={this.props.editState.insuredCarPlate}
                                                onValueChanged={this.props.plateChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'ARAÇ KULLANIM ŞEKLİ'}>
                                          <TextBox
                                                value={this.props.editState.insuredCarUsageType}
                                                onValueChanged={this.props.usageTypeChanged} />
                                    </FormGroupComponent>
                              </Col>
                              <Col xs>

                                    <FormGroupComponent label={'KİMLİK TİPİ DEĞERİ'}>
                                          <TextBox
                                                value={this.props.editState.insuredCarIdentityValue}
                                                onValueChanged={this.props.identityValueChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'MODEL ADI'}>
                                          <TextBox
                                                value={this.props.editState.insuredCarModelName}
                                                onValueChanged={this.props.modelNameChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'KM'}>
                                          <TextBox
                                                value={this.props.editState.insuredCarKilometer}
                                                onValueChanged={this.props.kilometerChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'RUHSAT NO'}>
                                          <TextBox
                                                value={this.props.editState.insuredCarLicense}
                                                onValueChanged={this.props.licenseChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'TRAFİĞE ÇIKIŞ TARİHİ'}>
                                          <TextBox
                                                value={this.props.editState.insuredCarOutToTrafficDate}
                                                onValueChanged={this.props.outToTrafficDateChanged} />
                                    </FormGroupComponent>

                                    <FormGroupComponent label={'MOTOR NO'}>
                                          <TextBox
                                                value={this.props.editState.insuredCarMotorNo}
                                                onValueChanged={this.props.motorNoChanged} />
                                    </FormGroupComponent>
                              </Col>
                        </Row>
                  </TabPane>

            );
      }
}